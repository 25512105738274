import React, { useState } from "react";
import { Container, Col, Button } from "react-bootstrap";


import '../Components/PostLayout/PostLayout.css'

const About = () => {

    const [more, setMore] = useState(false);
    const [tldr, setTldr] = useState(false);


    return (

        <>
            <Container className="about-container">
                <Col lg={10} className="">
                    <div className="post-content">
                        <p className="subtitle mt-0 pt-5">About the blog <span className="tldr" onClick={() => setTldr(!tldr)}>tldr?</span></p>
                        <div className={`post-text anim-elem ${!tldr ? 'expanded' : 'collapsed'}`}>
                            <p className="mt-0">My foray into anime and Japanese media began without any preconceptions. Like many kids of my era, I was familiar with Pokémon and Dragon Ball, alongside whatever popular series cable networks chose to license. It wasn't until years later, fueled by growing disappointment with Western productions and a growing interest in Japanese media sparked by video games, that I decided to delve into anime.</p>
                            <p>Expecting to find a production process similar to Western standards, where an endless committee of artists, writers, producers, and marketers collaborate to <div className="toggle-container"> <span className="shown"> "shape" </span> <span className="hidden"> lobotomize </span></div> an idea into a marketable and profitable product, a product that is sure to sell itself and its whole array of subscription services, supplementary books, plastic figures, apparel, and a perpetual amount of sequels, prequels, and spinoffs. Failure is never an option for these multi-million-dollar creations.</p>
                            <p>Of course, these aspects also exist in the Japanese market; it would be disingenuous of me to pretend otherwise. What truly astonished me, however, was the sheer volume of series produced each season. With an average of thirty to forty shows every three months, it seemed inherently unsustainable and yet it has been the norm for years, this sparked curiosity within me.</p>
                            <p>The more shows like these I watched, the more fascinated I became.   They range from artsy and avant-garde shows that wouldn't look out of place in a nightly spot in adult swim to completely generic and filled with tropes, sometimes even the stories of how they came to be are an entire tale themselves.</p>
                        </div>
                        <div className="post-text">
                            <p>Some people make a hobby out of reviewing old and forgotten anime that only exists in VHS, while others dissect artsy or popular anime and give them a completely subjective rating. Some even use tools to <div className="toggle-container"> <span className="shown">help them keep a record of everything they watched. </span> <span className="hidden">show everyone on forums how much e-cred they have.</span></div> Personally, I like to write about anime that was forgotten in plain sight, shows that came and went unnoticed for a variety of reasons, anime that fell <span style={{ fontFamily: 'Allerta Stencil', color: '#a2a7c4' }}>Through the gaps.</span></p>
                        </div>

                        <div className={`d-flex justify-content-center mb-5 ${more ? 'd-none' : 'd-flex'}`}>
                            <Button onClick={() => setMore(true)}>More?</Button>
                        </div>

                        <div className={`post-text anim-elem ${more ? 'expanded' : 'collapsed'}`}>
                            <p>Melodramatic bullshit aside, this blog was mostly done as an experiment in web development, i wanted to see how hard it was to create a blog entirely from scratch, naively thinking it shouldn't take more than a few days, i try to stick to native JS/CSS solutions as much as possible instead of having to rely on external libraries and services. No offense to all other anime blogs but Wordpress is ugly as sin.</p>
                            <p>Writing in a dedicated blog site like medium would have been infinitely easier but my goal is to sharpen my webdev skills, while adding functionality and styling a CMS doesn't normally includes.</p>
                            <p>This blog was also created as an excercise in creative writing, English is not my main language and despite having written thousands of <div className="toggle-container"> <span className="shown"> posts </span> <span className="hidden"> shitposts </span></div>  in many different forums over the years, i didn't realize until now how articulating an idea into a coherent and interesting piece is harder than i thought.</p>
                            <p>I hope you find something interesting here.</p>
                        </div>
                    </div>

                </Col>
            </Container>

        </>
    )
}

export default About;


// the way to make shit appear and dissapear is messy as fuck but it works, TODO fix this, probably with an external library