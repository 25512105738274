import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "./Header.css"
// import logo from "../../assets/bitcoin-logo.png"
import bannerimg from "../../assets/bannerbase.jpg"
import { useNavigate } from "react-router-dom";

const Header = () => {

    const navigate = useNavigate();

    return (
        <>
            <div className="banner-style">
                <img src={bannerimg} alt="banner"></img>
                <div className="banner-text" onClick={() => navigate('/home')}>Through the gaps
                    <div className={`subt `}>anime reviews and more...</div>
                </div>
            </div>
            <Navbar className="navbar-style">
                <Container>
                    <Nav className="">
                        <Nav.Link onClick={() => navigate('/')}>Home</Nav.Link>
                        <Nav.Link onClick={() => navigate('/about')}>About</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>

        </>

    )
}

export default Header