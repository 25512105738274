import React from "react";
import { Navigate } from "react-router-dom";


import Contact from "../pages/Contact"
import Home from "../pages/Home"
import About from "../pages/About";
import Nopage from "../pages/NoPage"
import Tester from "../pages/Tester";
import PostLayout from "../Components/PostLayout/PostLayout";


const routes = [
    // { path: "/contact", component: <Contact /> },
    { path: "/home", component: <Home /> },
    { path: "/about", component: <About /> },
    { path: "/blog/blogtest/tester", component: <Tester /> },
    { path: "/blog/:postId/:postTitle", component: <PostLayout /> },
    { path: "*", component: <Nopage /> },
    {
        path: "/",
        exact: true,
        component: < Navigate to="/home" />,
    },
]

export { routes };