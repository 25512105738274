import React from "react";
import { Routes, Route } from "react-router-dom";
import { routes } from "./routes"
import Layout from "./Components/Layout/Layout";

const App = () => {
  return (

    <Routes>

      {routes.map((route, idx) => (

        <Route
          path={route.path}
          element={
            <Layout>
              {route.component}
            </Layout>
          }
          key={idx}
          exact={true}

        />
      ))}

    </Routes>
  );
};

export default App;
