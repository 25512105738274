import React from "react";
import { Container } from "react-bootstrap";

const CustFooter = () => {
    return (
        <>
            <Container fluid style={{backgroundColor:'#060a20'}}>
                <div className="py-3">
                © 2024 Anime Through The Gaps
                </div>
            </Container>
        </>
    );
};
export default CustFooter;

