const carddata = [
    {
        id: '1',
        category:'dev',
        tags:['dev', 'react','not anime'],
        title:"We're (mostly) live!",
        year:null,
        subtitle:"the very first post of this whole thing, hopefully more to come.",
        date:'29-Mar-24',
        thumbnail:require("../assets/thumbs/thumb1.jpg"),
    },
    {
        id: '2',
        category:'anime',
        tags:['2022', 'anime'],
        title:'Warau Arsnotoria Sun!',
        year:'2022',
        subtitle:"The adventures of a handful of girls in a magical school, sounds pretty average. Is it?",
        date:'11-Apr-24',
        thumbnail:require("../assets/thumbs/thumb2.png"),
    },


]

export default carddata

