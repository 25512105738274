import { Carousel } from "react-bootstrap";
const BlogData = [
    {
        id: '1',
        pagedata:
            <>
                <p className="subtitle">Is this thing on?</p>
                <div className="post-text">
                    <p>After weeks of toil during my off-time, I finally managed to get this whole thing going. It's far from done, but it's good enough for me to call it a vee one point oh.</p>
                    <p className="subtitle">The How</p>
                    <p>I tried to challenge myself by making it as service agnostic as possible. Aside from a few libraries (Bootstrap, Router) and unavoidable services (domain registration, web hosting), it runs entirely without a database, CMS, or fancy web-builder. Alas, it seems I won't be able to escape their grip forever. Putting all the content of each entry in a single file will inevitably become a nightmare, and there's really no workaround if I ever want to implement things like comments. But that's a problem for the next iteration.</p>
                    <p className="subtitle">The What</p>
                    <p>If you're wondering what this is about, you can read the About page. Otherwise, the TL;DR is, I review things that I find interesting, mostly focusing on anime that isn't/wasn't popular.</p>
                    <p><span className="font-weight-bold">A disclaimer:</span> Not everything that I write about will fit into this description. Measuring popularity is historically a very hard thing to do, especially considering how modern media is not centralized in a single service. From now on, most, if not all, of the things I call “forgotten/popular” are considered so from a very subjective opinion. I try to inform myself using multiple sources, but discrepancies might arise at some point.</p>
                    <p className="subtitle">The Where</p>
                    <p>As to what's next, i already have several improvements on my sights</p>
                    <ul>
                        <li>Pagination for the main page</li>
                        <li>Adding a sidebar (its already there, i just don't have anything to put in it right now)</li>
                        <li>Adding a search functionality</li>
                        <li>Adding comments</li>
                        <li>Changing some styles</li>
                    </ul>
                    <p>That seems to be all for now, feel free to reach me out at animethroughthegaps@gmail.com</p>
                    <p>I hope you find something interesting here.</p>
                </div>
                <div className="d-flex justify-content-center mb-5">
                    <img src={require("../assets/1/screen1.jpg")} alt="images" />
                </div>
            </>
    },


    {
        id: '2',
        pagedata:
            <>
                <div className="infobox">
                    <p className="m-0">Release: 2022 </p>
                    <p className="m-0">Translated Title: Smile of the Arsnotoria the Animation </p>
                </div>
                <div className="post-text">
                    <p className="subtitle">Lost history</p>
                    <p>I generally try to avoid anime adaptations of mobile games, their "raison d'etre" is often just to promote their source material with the goal of attracting as many potential players as possible, which heavily limits its artistic freedom. Surprisingly <span className="titlspn">The Smile of the Arsnotoria</span> manages to stand on its own by ditching the cliches of the genre, which no doubt contributed to its low popularity.</p>
                    <p><span className="titlspn">The Smile of the Arsnotoria</span> is an adaptation of its mobile game of the same name, released in japan in 2021 and with a western release that never saw the light of day after the announcement of its End of Service shortly after the anime finished airing. It follows the daily life of five "Pentagrams" (astute viewers will realize they’re named after occultist books), magic students at Ashlam, a magical academy hidden with a magical seal.</p>
                    <p> While the main story of the game will likely be lost to the english-speaking audience, its anime remains self-contained enough to guarantee not requiring previous knowledge to be fully enjoyed.</p>
                    <img src={require("../assets/2/2.jpg")} alt="images" />
                    <p className="subtitle">Ashlam's Scenery</p>
                    <p>It's hard to put a definitive label to <span className="titlspn">The Smile of the Arsnotoria</span>, compared to its similar contemporaries it doesn't use comedy or slapstick humor, it doesn't try to tell an heroic story.</p>
                    <p> At times, it even feels like an arthouse project with its expansive shots of an eerily empty academy underscored by its discreet, serene soundtrack, still, these shots are always cut short by the cheery dialogue of one of Arsnotoria's friends, giving an interesting contrast between scenes.</p>
                    <img src={require("../assets/2/3.jpg")} alt="images" />
                    <p>This constant juxtaposition manifests in various shapes, most notably with our perceived Antagonists, a group of knights that provide a jarring intermission in the middle of almost every episode, pulling us from the vibrant adventures at Ashlam to a bleak, muted scene that even changes in aspect ratio, which invariably culminates in massacre, providing a foreboding tension throughout the length of the show.</p>
                    <img src={require("../assets/2/4.jpg")} alt="images" />
                    <p className="subtitle">Unconventionally Ordinary</p>
                    <p>What initially hooked me to this show was the absurdly normal and straight forwardness of its delivery, its first episode is a simple peek into the routine of our protagonists with (thankfully) little exposition dump that is organically threaded into the dialogue, where the main concern of our heroines is procuring biscuits for afternoon tea after which a discussion begins on the proper amount and order of which one should add milk to tea, leading into another argument: whether one should add the cream or the jam first to a scone, all of it unfolding in a single room.</p>
                    <img src={require("../assets/2/5.jpg")} alt="images" />
                    <p>But what really elevated the show to a memorable level was the underlying sense of melancholy that surfaced in some episodes.</p>
                    <p>In a certain episode, one of Arsnotoria's friends, while researching in Ashlam's library, tells Paulina, the resident scholar of the library, that no matter how much she seeks knowledge she can only grasp fragments of it,  to which Paulina responds that the more knowledge you obtain, the more apparent it becomes that there is much left to learn and she won't leave until she finds what she's looking for, while also mentioning that Ashlam is said to contain every book, past present or future (which one could even draw a parallel to Borges's Library of Babel).</p>
                    <p>While the scene is abruptly cut there it doesn't take much to realize the message Paulina was trying to convey.</p>
                    <img src={require("../assets/2/6.jpg")} alt="images" />
                    <p>The show is scattered with these small blink-and-you'll-miss-it moments, but at the end of the day <span className="titlspn">The Smile of the Arsnotoria</span> is predominantly a show about the lighthearted everyday of the residents of Ashlam, with a distinctively different approach to the slice of life genre, a fixation on almost cinematic shots highlighting its setting and its odd focus on food (of which there are many closeups).</p>
                    <Carousel>
                        <Carousel.Item>
                            <img src={require("../assets/2/c1.jpg")} class="d-block car-img" alt="images" text='first slide' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={require("../assets/2/c2.jpg")} class="d-block car-img" alt="images" text='secslide' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={require("../assets/2/c6.jpg")} class="d-block car-img" alt="images" text='third slide' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={require("../assets/2/c3.jpg")} class="d-block car-img" alt="images" text='third slide' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={require("../assets/2/c4.jpg")} class="d-block car-img" alt="images" text='third slide' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={require("../assets/2/c5.jpg")} class="d-block car-img" alt="images" text='third slide' />
                        </Carousel.Item>
                    </Carousel>
                    <p className="subtitle">Closing Thoughts</p>
                    <p><span className="titlspn">The Smile of the Arsnotoria</span> might have been a last attempt to save the dying mobile game, maybe contractual obligation forced the studio to create it or maybe the director realized that the game's doom was inevitable and decided to experiment with it it might have been too detached from the familiar elements of its genre, its episodic nature might have detracted those looking for a greater underlying narrative, the introduction and constant threat of the knights might have been too much for those looking for a "healing" anime, its themes might have been too removed from the game's to appeal to the remaining players.</p>
                    <p> Despite its flaws which might drive people away from it, its unique execution and atmosphere that is rarely seen in shows of its kind make it a personal favorite of mine.</p>
                    <img src={require("../assets/2/1.jpg")} className="mb-5" alt="images" />
                </div>
            </>
    },



]

export default BlogData