import React from "react";
import { Card, Button } from "react-bootstrap";
import carddata from "../../API/CardData";
import { useNavigate } from "react-router-dom";


const CardBuilder = () => {

    const navigate = useNavigate();
    const dateoptions = { month: 'long', day: 'numeric', year: 'numeric', };

    return (
        <>
            {[...carddata].reverse().map((Cdata, bitem) => (


                <Card className="mb-5 home-card" key={bitem}>
                    <Card.Header className={`${Cdata.category}`}>{Cdata.category}</Card.Header>
                    <Card.Img className="main-card-img" variant="top" src={Cdata.thumbnail} />
                    <Card.Body className={`${Cdata.category}`}>
                        <Card.Title>{Cdata.title} {Cdata.year!=null?('('+Cdata.year+')'):''}</Card.Title>
                        <Card.Subtitle>{new Date(Cdata.date).toLocaleDateString('en-us', dateoptions)}</Card.Subtitle>
                        <Card.Text>{Cdata.subtitle}</Card.Text>
                        <Button onClick={() => { navigate('/blog/'+ Cdata.id +'/'+ Cdata.title.replace(/\s+/g, '-')) }}>Read More</Button>
                    </Card.Body>
                </Card>
            ))}

        </>
    );
};
export default CardBuilder