import React from "react";
import { Container, Col, Card, } from "react-bootstrap";
import BlogData from "../../API/BlogData";
import carddata from "../../API/CardData";
import { useParams } from 'react-router-dom';
import ScrollToTop from "../Scroll/ScrollToTop";
import './PostLayout.css'

const PostLayout = () => {

    const { postId } = useParams();
    const cardData = carddata.find(item => item.id === postId);
    const matchedData = BlogData.find(item => item.id === postId);
    const dateoptions = { month: 'long', day: 'numeric', year: 'numeric', };
    return (
        <>
            <ScrollToTop/>
            <Container className="d-flex justify-content-center">
                <Col lg={10} className="mt-4">

                    <Card className="border-none by-0" >
                        {/* <Card.Header>{cardData.category}</Card.Header> */}
                        <Card.Img variant="top" src={cardData.thumbnail} />
                        <Card.Body className="blog-card">
                            <Card.Title className="d-flex justify-content-center blog-title">{cardData.title}</Card.Title>
                            <Card.Subtitle className="float-right">{new Date(cardData.date).toLocaleDateString('en-us', dateoptions)}</Card.Subtitle>
                            {/* <Card.Text>{cardData.subtitle}</Card.Text> */}
                        </Card.Body>
                    </Card>
                    
                    <div className="post-content">
                        {matchedData.pagedata}
                    </div>
                </Col>
            </Container>
        </>
    )
}

export default PostLayout;