import React from 'react';
import Header from '../Header/Header';
import CustFooter from '../Footer/CustFooter';

const Layout = ({ children }) => {
    return (
        <>

            <Header />

   
            <main className='main'>{children}</main>


            <CustFooter/>
        </>
    )
}

export default Layout;