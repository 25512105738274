import React from "react";

import { Container } from "react-bootstrap";
import { Col} from "react-bootstrap";
import CardBuilder from "../Components/CardBuilder/CardBuilder";

const Home = () => {

  // const navigate = useNavigate();


  return (
    <>
      <Container>

        <div className="pt-5 d-flex flex-wrap justify-content-around main-content">

          <Col lg={12} xl={8} style={{minHeight:"100vh"}}>
            <CardBuilder />
            <div>
              {/* TODO PAGINATION */}
              {/* <Button>Newer</Button>
              <Button>Older</Button> */}
            </div>
          </Col>

          <Col lg={12} xl={3} className="bg-secondary d-none"> 
            <div>

            </div>
          </Col>
        </div>

      </Container>
    </>
  );
};

export default Home;